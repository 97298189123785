import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { SERVER_URL } from "@/constant";

function CarouselCompo({ imgArray }) {
  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: true })
  );

  //console.log(imgArray);

  return (
    <Carousel
      plugins={[plugin.current]}
      onMouseEnter={plugin.current.stop}
      onMouseLeave={plugin.current.reset}
      className="w-full z-[-1]"
    >
      <CarouselContent>
        {imgArray &&
          imgArray.map((e, i) => (
            <CarouselItem key={i} className="rounded-2xl">
              <div className="h-[105%] md:h-[13rem] xl:h-[20rem] rounded-2xl">
                <img src={`${SERVER_URL}/api/v1/fetch/image/${e?.image}`} className="w-full h-full object-cover rounded-2xl" alt="" />
              </div>
            </CarouselItem>
          ))}
      </CarouselContent>
    </Carousel>
  );
}

export default CarouselCompo;
