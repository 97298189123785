import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";

function AdminPincode() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [catagory, setCatagory] = useState("");
  const [isAvailable, setIsAvailable] = useState(true);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/pincode/admin/pincode`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function changeStatus(id) {
    if (!id) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/pincode/change-status/${id}`,
        method: "put",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Update failed");
      console.log(error);
    } finally {
      setIsAvailable(true);
      setLoading(false);
    }
  }

  async function delDoctor(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/pincode/delete/${deleteProductId}`,
        method: "delete",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addCatagory() {
    console.log(catagory);
    console.log(isAvailable);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/pincode/add/${catagory}/${isAvailable}`,
        method: "get",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Pincode</Heading2>
        <div className="w-full flex gap-2 justify-end">
          <DialogBox title={"Add Pincode"}>
            <InputField
              label="Pincode"
              onChange={(e) => setCatagory(e?.target?.value)}
            />
            <div className="flex flex-col gap-2">
              <Label>Is Available</Label>
              <select
                className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                onChange={(e) => setIsAvailable(e.target.value)}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            <Button onClick={addCatagory}>Save</Button>
          </DialogBox>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Pincode</TableHead>
              <TableHead className="text-center">Active</TableHead>
              <TableHead className="text-center">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.pincode}</TableCell>
                  <TableCell>
                    <DialogBox title={`${e?.isAvailableNow}`}>
                      <div className="flex flex-col gap-2">
                        <Label>Is Available</Label>
                        <select
                          className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                          onChange={(e) => setIsAvailable(e.target.value)}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                      <Button onClick={() => changeStatus(e?._id)}>Save</Button>
                    </DialogBox>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="destructive"
                      onClick={() => delDoctor(e?._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminPincode;
