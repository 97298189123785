import React, { useState } from "react";
import DialogBox from "./DialogBox";
import InputField from "./InputField";
import { Button } from "./ui/button";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import AlertBox from "./AlertBox";
import { useNavigate } from "react-router-dom";

function AddAddress() {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  async function updateData(data) {
    data = { address: { ...data } };
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/add-address`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setOpen(true);
      setSuccess(true);
      setDialogOpen(false);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Successfully updated"}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Update failed"}
        />
      )}
      <DialogBox
        title={"Add Address"}
        desc={"Add Your Delivary Address"}
        dialogOpen={dialogOpen}
        onOpenChange={setDialogOpen}
      >
        <form className="space-y-3" onSubmit={handleSubmit(updateData)}>
          <InputField label="Address" {...register("address")} />
          <InputField label="Pin Code" {...register("pincode")} />
          <Button>Save</Button>
        </form>
      </DialogBox>
    </>
  );
}
export default AddAddress;
