import React from "react";

function Heading2({children, ...props}) {
  return (
    <h2 className={`scroll-m-20 pb-2 text-[20px] md:text-[30px] lg:text-[30px] font-semibold tracking-tight transition-colors first:mt-0`} {...props}>
      {children}
    </h2>
  );
}

export default Heading2;
