import { configureStore } from "@reduxjs/toolkit";
import phoneNumberSlice from "./phoneNumber.slice";
import authSlice from "./auth.slice";
import productDataSlice from "./productDataSlice";

export const store = configureStore({
    reducer: {
        phoneNumberSlice: phoneNumberSlice,
        auth: authSlice,
        productData: productDataSlice
    }
})