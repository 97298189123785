import React from "react";
import { Calendar } from "./ui/calendar";
import DialogBox from "./DialogBox";
import { Button } from "./ui/button";

function SelectCalander({ date, setDate, setSelectPlace }) {
  return (
    <DialogBox
      title={"Choose A Date"}
      className={"w-auto"}
      desc={"Select a date for your test"}
    >
      <div className="w-full space-y-2">
        <p className="font-semibold">Test at</p>
        <div className="w-full flex justify-center gap-2">
          <Button className="w-full" onClick={() => setSelectPlace("home")}>
            Home
          </Button>
          <Button className="w-full" onClick={() => setSelectPlace("lab")}>
            Lab
          </Button>
        </div>
      </div>
      <Calendar
        mode="single"
        selected={date}
        onSelect={setDate}
        className="w-full rounded-md border"
      />
    </DialogBox>
  );
}

export default SelectCalander;
