import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { Button } from "@/components/ui/button";
import Heading from "@/components/Heading";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import Loading from "@/components/Loading";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import { login } from "@/store/auth.slice";

function VerifyOtp({ apiRoute }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate()

  const dispatch = useDispatch()

  console.log(apiRoute);

  const phoneNumber = useSelector((state) => state.phoneNumberSlice.phoneNumber);
  console.log(phoneNumber);

  async function verify() {
    const otp = value;
    console.log(otp);
    const data = {
      mobileNumber: phoneNumber,
      otp : otp
    }
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: apiRoute,
        method: "post",
        data: data,
        withCredentials: true
      });
      console.log(res.data);
      if (res.status >= 200 || res.status < 400) {
        dispatch(login({status: true}))
        navigate("/")
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setErrorMessage("OTP not verified")
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex justify-center items-center flex-col mt-[2rem] gap-5">
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <img src={logo} className="w-[10rem]" alt="" />
      <div className="w-full flex justify-center items-center flex-col mt-[8rem]">
        <section className="mb-5  text-center">
          <Heading>OTP Verification</Heading>
          <p className={"text-slate-600 mt-3"}>
            Please enter the one-time-password that we have sent to{" "}
            <span className="underline">{phoneNumber}</span>
          </p>
        </section>
        <InputOTP
          maxLength={6}
          value={value}
          onChange={(value) => setValue(value)}
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} className="w-[3rem] h-[3rem] text-[18px]" />
            <InputOTPSlot index={1} className="w-[3rem] h-[3rem] text-[18px]" />
            <InputOTPSlot index={2} className="w-[3rem] h-[3rem] text-[18px]" />
            <InputOTPSlot index={3} className="w-[3rem] h-[3rem] text-[18px]" />
            <InputOTPSlot index={4} className="w-[3rem] h-[3rem] text-[18px]" />
            <InputOTPSlot index={5} className="w-[3rem] h-[3rem] text-[18px]" />
          </InputOTPGroup>
        </InputOTP>
      </div>
      {loading === true ? (
        <Loading color={"bg-sky-500"} />
      ) : (
        <Button onClick={verify}>Verify OTP</Button>
      )}
    </div>
  );
}

export default VerifyOtp;