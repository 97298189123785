import DialogBox from "@/components/DialogBox";
import Heading from "@/components/Heading";
import Heading2 from "@/components/Heading2";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import { Link } from "react-router-dom";

function EditProduct() {
  const productData = useSelector((state) => state.productData?.productData);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [productId, setProductId] = useState("");
  const [brands, setBrands] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [subCatagories, setSubCatagories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const { register: infoRegister, handleSubmit: handleInfoSubmit } = useForm();
  const { register: groupRegister, handleSubmit: handleGroupSubmit } =
    useForm();
  const { register: keywordRegister, handleSubmit: handleKeywordSubmit } =
    useForm();

  async function updateInfo(data) {
    if(productId?.length > 0) {
      data = {...data, _id: productId}
    }
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/add-product`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setProductId(res.data?.data?._id);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setProductId(productData?._id);
  }, []);

  async function addGroup(data) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    data = { groups: data, productId: productId };
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/add-to-group`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setProductId(res.data?.data?._id);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCatagories() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/catagories`,
        method: "get",
        withCredentials: true,
      });
      setCatagories(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchSubCatagories() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/subcatagories`,
        method: "get",
        withCredentials: true,
      });
      setSubCatagories(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchBrands() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/brands`,
        method: "get",
        withCredentials: true,
      });
      setBrands(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delGroup(group) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    const data = { productId: productId, groups: { ...group } };
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/del-from-group`,
        method: "delete",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addKeyword(data) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    data = { productId: productId, keywords: data?.keyword };
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/keyword/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setProductId(res.data?.data?._id);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
    } finally {
      setLoading(false);
    }
  }

  function handleFileChange(event) {
    const arrayFiles = Array.from(event.target.files);
    if (arrayFiles.length <= 5) {
      setFiles(arrayFiles);
    } else {
      setOpen(true);
      setSuccess(false);
      setMessage("You can upload 5 photos maximum");
    }
  }

  async function uploadImage() {
    if (files?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Select at least one file");
    }
    const formData = new FormData();

    files.map((e, i) => {
      formData.append("productImg", e);
    });
    formData.append("_id", productId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/add-product`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      console.log(res.data);
      setProductId(res.data?.data?._id);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBrands();
    fetchCatagories();
    fetchSubCatagories()
  }, []);

  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full text-center">
        <Heading2>Add or Update Product</Heading2>
      </div>
      <div className="w-full">
        <form
          className="w-full flex flex-col gap-5 justify-center items-center mt-5"
          onSubmit={handleInfoSubmit(updateInfo)}
        >
          <div className="w-full flex flex-wrap gap-5 justify-center items-end mt-5">
            <InputField
              label="Product Name"
              {...infoRegister("title")}
              defaultValue={productData?.title}
            />
            <InputField
              label="Manufacturer Name"
              {...infoRegister("mkt")}
              defaultValue={productData?.mkt}
            />
            <div className="flex flex-col gap-2">
              <Label>
                Select Type{" "}
                {productData?.type && (
                  <span className="text-slate-500">
                    (Prev: {productData?.type})
                  </span>
                )}
              </Label>
              <select
                className="w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                {...infoRegister("type")}
                defaultValue={productData?.type}
              >
                <option value="product">Product</option>
                <option value="medicine">Medicine</option>
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <Label>
                Select Catagory{" "}
                {productData?.catagory && (
                  <span className="text-slate-500">
                    (Prev: {productData?.catagory})
                  </span>
                )}
              </Label>
              <select
                className="w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                {...infoRegister("catagory")}
              >
                {catagories?.map((e, i) => (
                  <option value={e?.name} key={i}>
                    {e?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <Label>
                Select Subcatagory{" "}
                {productData?.subCatagory && (
                  <span className="text-slate-500">
                    (Prev: {productData?.subCatagory})
                  </span>
                )}
              </Label>
              <select
                className="w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                {...infoRegister("subCatagory")}
              >
                {subCatagories?.map((e, i) => (
                  <option value={e?.name} key={i}>
                    {e?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <Label>
                Select Brand{" "}
                {productData?.brand && (
                  <span className="text-slate-500">
                    (Prev: {productData?.brand})
                  </span>
                )}
              </Label>
              <select
                className="w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                defaultValue={""}
                {...infoRegister("brand")}
              >
                {brands?.map((e, i) => (
                  <option value={e?.name} key={i}>
                    {e?.name}
                  </option>
                ))}
              </select>
            </div>
            <InputField
              label="Discount"
              {...infoRegister("discount")}
              defaultValue={productData?.discount}
            />
            <InputField
              label="Consume Type"
              {...infoRegister("consumeType")}
              defaultValue={productData?.consumeType}
            />
            <InputField
              label="Country Of Origin"
              {...infoRegister("countryOfOrigin")}
              defaultValue={productData?.consumeType}
            />
            <InputField
              label="Description"
              {...infoRegister("description")}
              defaultValue={productData?.description}
            />
          </div>
          <Button>Save</Button>
        </form>
        <div className="w-full flex justify-center items-center flex-col mt-5">
          <p className="bg-green-500 w-full text-center py-2 text-[17px] font-bold text-white">
            Groups
          </p>
          <div className="w-full flex justify-center items-center mt-5 gap-5 mb-5">
            {productData?.groups?.map((j, i) => (
              <div className="border px-3 py-3 rounded-md" key={i}>
                <p>Title : {j?.title}</p>
                <p>Available : {j?.isAvailable ? "Yes" : "No"}</p>
                <p>Manufacture Date : {j?.manufactureDate}</p>
                <p>Expire Date : {j?.expiryDate}</p>
                <p>MRP : {j?.mrp}</p>
                <p>Price : {j?.price}</p>
                <p>Description : {j?.desc}</p>
                <Button
                  variant="destructive"
                  className="mt-2"
                  onClick={() => delGroup(j)}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
          <DialogBox title={"Add Group"}>
            <form className="space-y-3" onSubmit={handleGroupSubmit(addGroup)}>
              <InputField label="Title" {...groupRegister("title")} />
              <InputField
                label="Manufacture Date"
                type="date"
                {...groupRegister("manufactureDate")}
              />
              <InputField
                label="Expiry Date"
                type="date"
                {...groupRegister("expiryDate")}
              />
              <InputField label="Description" {...groupRegister("desc")} />
              <InputField label="MRP" {...groupRegister("mrp")} />
              <InputField label="Price" {...groupRegister("price")} />
              <div className="flex flex-col gap-2">
                <Label>Available</Label>
                <select
                  className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                  defaultValue={""}
                  {...groupRegister("isAvailable")}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <Button>Save</Button>
            </form>
          </DialogBox>
        </div>
        <div className="w-full flex justify-center items-center flex-col mt-5">
          <p className="bg-green-500 w-full text-center py-2 text-[17px] font-bold text-white">
            Keywords
          </p>
          <div className="w-full flex justify-center items-center mt-5 gap-5 mb-5">
            {productData?.keywords?.map((j, i) => (
              <div className="border px-3 py-3 rounded-md" key={i}>
                <p>{j}</p>
              </div>
            ))}
          </div>
          <DialogBox title={"Add Keyword"}>
            <form
              className="space-y-3"
              onSubmit={handleKeywordSubmit(addKeyword)}
            >
              <InputField label="Keyword" {...keywordRegister("keyword")} />
              <Button>Save</Button>
            </form>
          </DialogBox>
        </div>
        <div className="w-full flex justify-center items-center flex-col mt-5">
          <p className="bg-green-500 w-full text-center py-2 text-[17px] font-bold text-white">
            Pictures
          </p>
          <div className="w-full flex justify-center items-center mt-5 gap-5 mb-5">
            {productData?.pictures?.map((j, i) => (
              <img
                src={`${SERVER_URL}/api/v1/fetch/image/${j}`}
                className="w-[7rem]"
              />
            ))}
          </div>
          <div className="flex items-end gap-2">
            <InputField
              label={
                <p>
                  Pictures{" "}
                  <span className="text-slate-500 text-[13px] font-semibold">
                    5 photos max
                  </span>
                </p>
              }
              type="file"
              onChange={(e) => handleFileChange(e)}
              multiple
            />
            <Button onClick={uploadImage}>Upload</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
