import UploadPrescription from "@/components/UploadPrescription";
import React, { useEffect, useState } from "react";
import HorizontalCatagoryBar from "@/components/HorizontalCatagoryBar";
import LabProduct from "@/components/LabProduct";
import { SERVER_URL } from "@/constant";
import axios from "axios";

function LabTest() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [productData, setProductData] = useState([]);
  const [catagories, setCatagories] = useState([]);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/all`,
        method: "get",
        withCredentials: true,
      });
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCatagory() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/catagory`,
        method: "get",
        withCredentials: true,
      });
      setCatagories(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchByCatagory(catagory) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/catagory?catagory=${catagory}`,
        method: "get",
        withCredentials: true,
      });
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    fetchCatagory();
  }, []);

  console.log(productData);

  return (
    <div className="w-full flex justify-center items-center flex-col">
      <div className="w-[90vw] flex flex-col justify-center items-center">
        <UploadPrescription />
        <HorizontalCatagoryBar
          catagories={catagories}
          fetchByCatagory={fetchByCatagory}
        />
        <div className="w-full flex gap-2 flex-wrap mt-5 justify-center items-center">
          {productData &&
            productData?.map((e, i) => (
              <LabProduct
                title={e?.title}
                discount={e?.discount}
                mrp={e?.mrp}
                price={e?.price}
                testArray={e?.includeTests}
                key={i}
                id={e?._id}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default LabTest;
