import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useState } from "react";
import InputField from "./InputField";
import { FaLocationDot } from "react-icons/fa6";
import { SiTicktick } from "react-icons/si";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Button } from "./ui/button";
import { Label } from "./ui/label";

function PincodeCompo() {
  const [userPincode, setUserPincode] = useState("");
  const [pincodeAvailable, setPincodeAvailable] = useState(undefined);
  const [loading, setLoading] = useState(false);

  async function checkPincode() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/pincode/client/pincode/${userPincode}`,
        method: "get",
      });
      setPincodeAvailable(true);
    } catch (error) {
      setPincodeAvailable(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-3">
      {/* <Label className="text-slate-500">Please Enter Your Pincode</Label> */}
      <div className="flex items-center justify-center gap-3">
        <FaLocationDot size={"30px"} className="text-[#37B7C3]" />
        <InputField
          onChange={(e) => setUserPincode(e.target.value)}
          placeholder="Enter Your Pincode"
        />
        <Button onClick={checkPincode}>Apply</Button>
      </div>
      {pincodeAvailable === true && (
        <p className="flex items-center gap-2 font-semibold text-green-500">
          <SiTicktick />
          Delivary is available
        </p>
      )}
      {pincodeAvailable === false && (
        <p className="flex items-center gap-2 font-semibold text-red-500">
          <BsExclamationCircleFill />
          Delivary is not available
        </p>
      )}
    </div>
  );
}

export default PincodeCompo;
