import React, { useRef } from "react";
import { handleMouseDown } from "@/utils/mouseScroll";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";

function HorizontalCatagoryBar({ catagories, fetchByCatagory }) {
  const scrollRef = useRef(null);

  return (
    <div
      className="w-full flex gap-2 overflow-auto cursor-grabbing mt-5"
      style={{ scrollbarWidth: "none" }}
      ref={scrollRef}
      onMouseDown={(e) => handleMouseDown(e, scrollRef)}
    >
      {catagories &&
        catagories.map((e, i) => (
          <Button
            variant="outline"
            className="h-[4rem]"
            onClick={() => fetchByCatagory(e?.name)}
          >
            <div className="flex flex-col justify-start items-start">
              <p className="text-[13px] font-semibold">{e.name}</p>
              <Link className="flex justify-center items-center text-[10px] text-slate-500">
                Click Here <IoIosArrowForward />
              </Link>
            </div>
          </Button>
        ))}
    </div>
  );
}

export default HorizontalCatagoryBar;
