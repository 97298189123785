import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";

function AdminCoupon() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [days, setDays] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [couponName, setCouponName] = useState("");
  const [discountType, setDiscountType] = useState("amount");
  const [isPublic, setIsPublic] = useState(true);
  const [couponFor, setCouponFor] = useState("product");
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/coupon/admin/get`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delDoctor(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
      return null;
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/coupon/delete/${deleteProductId}`,
        method: "delete",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addCatagory() {
    if (couponName?.length === 0 || discount <= 0 || days <= 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Select valid values of coupon name, expire day and discount");
      return null;
    }
    console.log(discountType);
    console.log(isPublic);
    console.log(couponFor);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/coupon/add`,
        method: "post",
        data: {
          couponName: couponName.toUpperCase().replace(/ /g, "-"),
          discountType,
          discount,
          days,
          isPublic,
          couponFor,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Coupons</Heading2>
        <div className="w-full flex gap-2 justify-end">
          <DialogBox title={"Add Coupon"}>
            <InputField
              label="Coupon Name"
              onChange={(e) => setCouponName(e.target.value)}
            />
            <InputField
              label="Discount"
              onChange={(e) => setDiscount(e.target.value)}
            />
            <div className="flex flex-col gap-2">
              <Label>Select Discount Type</Label>
              <select
                value={discountType}
                className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <option value="amount">Amount</option>
                <option value="percentage">Percentage</option>
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <Label>Select Coupon For</Label>
              <select
                value={couponFor}
                className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                onChange={(j) => setCouponFor(j?.target?.value)}
              >
                <option value="product">Products</option>
                <option value="medicine">Medicines</option>
                <option value="lab">Lab</option>
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <Label>Select Privacy</Label>
              <select
                value={isPublic}
                className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                onChange={(k) => setIsPublic(k?.target?.value)}
              >
                <option value="true">Public</option>
                <option value="false">Private</option>
              </select>
            </div>
            <InputField
              label="Expires in days"
              onChange={(e) => setDays(e.target.value)}
            />
            <DialogClose asChild>
              <Button onClick={addCatagory}>Save</Button>
            </DialogClose>
          </DialogBox>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Coupon</TableHead>
              <TableHead className="text-center">Discount</TableHead>
              <TableHead className="text-center">Discount Type</TableHead>
              <TableHead className="text-center">Coupon For</TableHead>
              <TableHead className="text-center">Privacy</TableHead>
              <TableHead className="text-center">Expires At</TableHead>
              <TableHead className="text-center">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.coupon}</TableCell>
                  <TableCell>
                    {e?.discountType === "amount"
                      ? `Rs. ${e?.discount}`
                      : `${e?.discount}%`}
                  </TableCell>
                  <TableCell>{e?.discountType}</TableCell>
                  <TableCell>{e?.couponFor}</TableCell>
                  <TableCell>{`${
                    e?.isPublic === true ? "Public" : "Private"
                  }`}</TableCell>
                  <TableCell>{e?.expireAt?.toString().split("T")[0]}</TableCell>
                  <TableCell>
                    <Button
                      variant="destructive"
                      onClick={() => delDoctor(e?._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminCoupon;
