import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

function AccrodianBox({dataArray}) {

  return (
    <Accordion type="single" collapsible className="w-full">
      {dataArray &&
        dataArray.map((e, i) => (
          <AccordionItem value={`item-${i}`} key={i} className="border-b-[1px]">
            <AccordionTrigger className="text-[20px] lg:text-[27px] font-semibold">{e?.title}</AccordionTrigger>
            <AccordionContent className="text-slate-500">
              {e?.content}
            </AccordionContent>
          </AccordionItem>
        ))}
    </Accordion>
  );
}

export default AccrodianBox;
