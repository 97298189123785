import CardComponent from "@/components/CardComponent";
import React, { useState } from "react";
import men from "@/assets/man.png";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { useSelector } from "react-redux";
import { SERVER_URL } from "@/constant";
import { useForm } from "react-hook-form";
import AddAddress from "@/components/AddAddress";
import AddPatient from "@/components/AddPatient";
import Loading from "@/components/Loading";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import Heading2 from "@/components/Heading2";

function MyProfile() {
  const { register, handleSubmit } = useForm();
  const userData = useSelector((state) => state.auth?.userData);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  console.log(userData);

  async function updateData(data) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/profile-settings`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function updateImage() {
    console.log(file);
    const formData = new FormData();
    formData.append("profilePicture", file);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/profile-settings`,
        method: "post",
        data: formData,
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      });
      console.log(res.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteAddress(id) {
    let data = {
      address: {
        _id: id,
      },
    };
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/delete-address`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setOpen(true);
      setSuccess(true);
      setMessage("Address deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Address delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[90vw] flex flex-col justify-center items-center">
        <CardComponent width="w-[100%]">
          <div className="w-full flex flex-col justify-center items-center p-3">
            <div className="flex flex-col justify-center items-center gap-3">
              <img
                src={`${SERVER_URL}/api/v1/fetch/image/${userData?.picture}`}
                className="w-[8rem] rounded-full h-[8rem]"
                alt=""
              />
              <div className="flex items-end gap-2">
                <InputField
                  label="Profile Picutre"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <Button onClick={updateImage}>Save</Button>
              </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center gap-5">
              <form
                className="mt-5 w-full flex flex-col justify-center items-center gap-5"
                onSubmit={handleSubmit(updateData)}
              >
                <div className="flex w-full flex-col items-center justify-center gap-10">
                  <div className="w-full items-center justify-center flex flex-col gap-5">
                    <div className="bg-[#37B7C3] w-full text-center py-3 text-white font-bold">
                      Personal Information
                    </div>
                    <div className="w-full flex flex-wrap gap-3 justify-center items-center">
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="First Name"
                          defaultValue={userData?.firstName}
                          {...register("firstName")}
                        />
                      </div>
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="Last Name"
                          defaultValue={userData?.lastName}
                          {...register("lastName")}
                        />
                      </div>
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="Phone Number"
                          defaultValue={userData?.mobileNumber}
                          {...register("mobileNumber")}
                        />
                      </div>
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="Email"
                          {...register("email")}
                          defaultValue={userData?.email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {loading === true ? (
                  <Loading />
                ) : (
                  <Button className="">Save</Button>
                )}
              </form>
              <div className="w-full items-center justify-center flex flex-col gap-5">
                <div className="bg-[#37B7C3] w-full text-center py-3 text-white font-bold">
                  Address
                </div>
                <div className="flex w-full flex-wrap gap-3 items-center">
                  {userData?.address?.length === 0 && (
                    <div className="w-full text-center">
                      <Heading2>No address found</Heading2>
                      <AddAddress />
                    </div>
                  )}
                  {userData?.address?.length > 0 &&
                    userData?.address?.map((e, i) => (
                      <section
                        className="w-[25rem] border-slate-300 rounded-lg border px-5 py-3"
                        key={i}
                      >
                        <p className="font-semibold text-[16px]">Address</p>
                        <p className="text-[14px] text-slate-600">
                          {e?.address}
                        </p>
                        <p className="font-semibold text-[16px]">Pincode</p>
                        <p className="text-[14px] text-slate-600">
                          {e?.pincode}
                        </p>
                        <div className="flex w-full items-center mt-3">
                          <Button
                            variant="destructive"
                            onClick={() => deleteAddress(e?._id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </section>
                    ))}
                </div>
                {userData?.address?.length > 0 &&
                  userData?.address?.length < 4 && <AddAddress />}
              </div>
              <div className="items-center justify-center flex flex-wrap gap-5 w-full">
                <div className="bg-[#37B7C3] w-full text-center py-3 text-white font-bold">
                  Patient Information
                </div>
                <div className="flex w-full flex-wrap gap-3 items-center">
                  {userData?.patient?.length === 0 && (
                    <div className="w-full text-center">
                      <Heading2>No patient found</Heading2>
                      <AddPatient />
                    </div>
                  )}
                  {userData?.patient?.length > 0 &&
                    userData?.patient?.map((e, i) => (
                      <section className="w-[25rem] border-slate-300 rounded-lg border px-5 py-3 space-y-1" key={i}>
                        <p className="font-semibold text-[16px]">
                          Patient Name
                        </p>
                        <p className="text-[14px] text-slate-600">
                          {`${e?.firstName} ${e?.lastName}`}
                        </p>
                        <p className="font-semibold text-[16px]">Patient DOB</p>
                        <p className="text-[14px] text-slate-600">{`${e?.dob}`}</p>
                        <p className="font-semibold text-[16px]">
                          Patient Problem
                        </p>
                        <p className="text-[14px] text-slate-600">{`${e?.problem}`}</p>
                        <div className="flex w-full items-center mt-3">
                          <Button variant="destructive">Delete</Button>
                        </div>
                      </section>
                    ))}
                </div>
                {userData?.patient?.length > 0 &&
                  userData?.patient?.length < 4 && <AddPatient />}
              </div>
            </div>
          </div>
        </CardComponent>
        <div></div>
      </div>
    </div>
  );
}

export default MyProfile;
