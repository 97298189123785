import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import LabCartCompo from "@/components/LabCartCompo";
import { Button } from "@/components/ui/button";
import vicks from "@/assets/vicks.bmp";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import DialogBox from "@/components/DialogBox";
import { Link, useSearchParams } from "react-router-dom";

function MyOrder() {
  const [productData, setProductData] = useState([]);
  const [labData, setLabData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();

  const orderSuccess = searchParams.get("success");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/my-orders`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Orders fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full flex justify-center items-center flex-col">
        {orderSuccess === "true" ? (
          <p className="text-[20px] font-semibold text-center mb-5 text-green-500 px-2">
            Congratulations, your order has been successfully placed
          </p>
        ) : null}
        {orderSuccess === "false" ? (
          <p className="text-[20px] font-semibold text-center mb-5 text-red-500 px-2">
            Sorry, order could not placed. Please try again.
          </p>
        ) : null}
        <div className="w-[90vw] flex flex-wrap justify-center items-center gap-5">
          {productData &&
            productData?.map((e, i) => (
              <section
                className="flex flex-col w-[90vw] sm:w-[30rem] border items-start p-3 rounded-lg flex-shrink-0"
                key={i}
              >
                <div className="w-full flex justify-between items-center mb-2 border-b-[1px] pb-2">
                  <div>
                    <p
                      className={`px-2 rounded-md text-white text-[13px] font-semibold ${
                        e?.delivaryStatus === "canceled"
                          ? "bg-red-500"
                          : "bg-green-500"
                      }`}
                    >
                      Status: {e?.delivaryStatus?.toUpperCase()}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className="text-left">
                    <p className="text-[20px] font-bold">
                      Bought {e?.products?.length} item
                    </p>
                    <p className="text-[13px] text-slate-500">
                      <span className="font-semibold">Payment</span>{" "}
                      {e?.paymentMethod?.toUpperCase()}
                    </p>
                    <p className="text-[13px] font-semibold text-slate-500">
                      {e?.paymentStatus}
                    </p>
                    <p className="text-[20px] font-bold">₹{e?.totalPrice}</p>
                    <DialogBox
                      title={"See More"}
                      buttonClass={
                        "h-0 p-0 border-none font-semibold text-slate-500"
                      }
                      contentTitle={"You have bought"}
                    >
                      <div className="w-full">
                        {e?.products?.map((j, i) => (
                          <div className="w-full flex justify-between items-center">
                            <p>{j?.product?.title}</p>
                            <p>₹{j?.product?.groups[j?.groupIndex]?.price}</p>
                          </div>
                        ))}
                      </div>
                    </DialogBox>
                  </div>
                </div>
              </section>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MyOrder;
