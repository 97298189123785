import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { SiTicktick } from "react-icons/si";
import { Link, useParams, useSearchParams } from "react-router-dom";

function LabOrder() {
  const [labData, setLabData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const orderSuccess = searchParams.get("success");

  async function fetchLabData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/my-tests`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setLabData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLabData();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-5">
      {orderSuccess === "true" ? (
        <p className="text-[20px] font-semibold text-center mb-5 text-green-500 px-2">
          Congratulations, your order has been successfully placed
        </p>
      ) : null}
      {orderSuccess === "false" ? (
        <p className="text-[20px] font-semibold text-center mb-5 text-red-500 px-2">
          Sorry, order could not placed. Please try again.
        </p>
      ) : null}
      <div className="w-full flex flex-wrap justify-center items-center gap-5">
        {labData &&
          labData?.map((e, i) => (
            <section
              className="flex flex-col mb-3 w-[90vw] sm:w-[30rem] border items-start p-3 rounded-lg flex-shrink-0"
              key={i}
            >
              <div className="w-full flex flex-wrap gap-2 justify-between items-center mb-2 border-b-[1px] pb-2">
                <div>
                  <p
                    className={`px-2 rounded-md text-white text-[13px] font-semibold ${
                      e?.testStatus === "canceled"
                        ? "bg-red-500"
                        : "bg-green-500"
                    }`}
                  >
                    Status: {e?.testStatus?.toUpperCase()}
                  </p>
                </div>
                {e?.report?.length > 0 ? (
                  <Button asChild>
                    <Link
                      to={`${SERVER_URL}/api/v1/fetch/image/${e?.report}`}
                      target="_blank"
                    >
                      Download Report
                    </Link>
                  </Button>
                ) : (
                  <Button disabled>Download Report</Button>
                )}
              </div>
              <div className="w-full flex justify-between items-center">
                <div className="text-left">
                  <p className="text-[20px] font-bold">{e?.test?.title}</p>
                  <p className="text-[13px] text-slate-500">
                    <span className="font-semibold">Payment</span>{" "}
                    {e?.paymentMethod?.toUpperCase()}
                  </p>
                  <p className="text-[13px] font-semibold text-slate-500">
                    {e?.paymentStatus}
                  </p>
                  <p className="text-[20px] font-bold">₹{e?.test?.price}</p>
                  <DialogBox
                    title={"Included tests"}
                    buttonClass={
                      "h-0 p-0 border-none font-semibold text-slate-500"
                    }
                    contentTitle={"You have bought"}
                  >
                    <div className="w-full">
                      {e?.test?.includeTests?.map((j, i) => (
                        <div className="w-full flex justify-between items-center">
                          <p>{j}</p>
                        </div>
                      ))}
                    </div>
                  </DialogBox>
                </div>
              </div>
            </section>
          ))}
      </div>
    </div>
  );
}

export default LabOrder;
