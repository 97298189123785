import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import AlertBox from "@/components/AlertBox";

function AllOrderedTests() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [reload, setReload] = useState(false)
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/tests/all`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleFileChange(event) {
    const selectedPdf = event.target.files[0];
    setFile(selectedPdf);
  }

  async function uploadPdf(paymentId) {
    const formData = new FormData();
    console.log(paymentId);
    console.log(file);

    formData.append("testReport", file);
    formData.append("paymentId", paymentId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/test/upload-report`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      console.log(res.data);
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  console.log(productsData);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Lab Test History</Heading2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-left">Test Name</TableHead>
              <TableHead className="text-left">Included Tests</TableHead>
              <TableHead className="text-center">Patient Name</TableHead>
              <TableHead className="text-center">User Mobile No</TableHead>
              <TableHead className="text-center">Price</TableHead>
              <TableHead className="text-center">Payment Method</TableHead>
              <TableHead className="text-center">Payment Status</TableHead>
              <TableHead className="text-center">Test Status</TableHead>
              <TableHead className="text-center">Place</TableHead>
              <TableHead className="text-center">Address</TableHead>
              <TableHead className="text-center">Pincode</TableHead>
              <TableHead className="text-center">Report</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell className="text-left">{e?.test?.title}</TableCell>
                  <TableCell>
                    <DialogBox
                      title={`${e?.test?.includeTests?.length} Tests`}
                      contentTitle={"Included Tests"}
                    >
                      {e?.test?.includeTests?.map((j, i) => (
                        <div className="border px-3 py-3 rounded-md" key={i}>
                          <p>{j}</p>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>{`${e?.patient?.firstName} ${e?.patient?.lastName}`}</TableCell>
                  <TableCell>{e?.user?.mobileNumber}</TableCell>
                  <TableCell>{e?.totalPrice}</TableCell>
                  <TableCell>{e?.paymentMethod}</TableCell>
                  <TableCell>{e?.paymentStatus}</TableCell>
                  <TableCell>{e?.testStatus}</TableCell>
                  <TableCell>{e?.place}</TableCell>
                  <TableCell>{e?.address?.address}</TableCell>
                  <TableCell>{e?.address?.pincode}</TableCell>
                  <TableCell>
                    <DialogBox title={"Report"}>
                      <div className="w-full space-y-3">
                        <div className="w-full rounded-lg">
                          <InputField
                            label="Upload Report"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <Button
                            className="w-full mt-3"
                            onClick={() => uploadPdf(e?._id)}
                          >
                            Upload
                          </Button>
                        </div>
                        <div className="mt-3 mb-3 h-[1px] bg-slate-300 w-full"></div>
                        {e?.report?.length > 0 && (
                          <Button asChild className="w-full">
                            <Link
                              to={`${SERVER_URL}/api/v1/fetch/image/${e?.report}`}
                              target="_blank"
                            >
                              View Report
                            </Link>
                          </Button>
                        )}
                      </div>
                    </DialogBox>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AllOrderedTests;
