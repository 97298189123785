import React, { useRef, useState } from "react";
import { Button } from "./ui/button";
import { handleMouseDown } from "@/utils/mouseScroll";

function SelectDate({ dateArray = [], setSelectedDate, selectedDate }) {
  const scrollRef = useRef(null);
  const [times, setTimes] = useState(dateArray[0]?.times);
  const [date, setDate] = useState(dateArray[0].weekday);

  console.log(dateArray[0].weekday);

  return (
    <div className="w-full flex flex-col gap-2">
      <select
        onChange={(e) => {
          const index = e.target.value;
          setTimes(dateArray[index]?.times);
          setDate(dateArray[index]?.weekday);
        }}
        className="bg-white border px-2 py-2 rounded-md"
      >
        {dateArray.map((e, i) => (
          <option value={i}>{e.weekday}</option>
        ))}
      </select>
      <div className="flex flex-wrap gap-2">
        {times.map((e, i) => (
          <div>
            {e?.length > 0 && (
              <Button
                className={`w-[5rem] text-[13px] ${
                  e === selectedDate?.time ? "bg-[#088395]" : ""
                }`}
                key={i}
                onClick={() => setSelectedDate({ date, time: e })}
              >
                {e}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectDate;
