import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productData : null
}

const productData = createSlice({
    name: "productData",
    initialState,
    reducers: {
        setProductData : (state, action) => {
            state.productData = action.payload.productData
        },
        delProductData : (state) => {
            state.productData = null
        }
    }
})

export const {setProductData, delProductData} = productData.actions
export default productData.reducer