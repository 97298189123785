import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { Button } from "./ui/button";
import { Link } from "react-router-dom";

function DropDownBox({ title, menuArray, buttonArray }) {
  //console.log(menuArray);

  if(buttonArray?.length > 0) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">{title}</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[12rem] py-2 flex flex-col">
          {buttonArray.map((e, i) => (
            <div>{e}</div>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">{title}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 p-3 flex flex-col gap-2">
        {menuArray.map((e, i) => (
          <Link to={e.link} key={i} className="w-full hover:border-none">
            <DropdownMenuItem className="flex items-center gap-2 text-[12px] font-medium hover:bg-[#088395] p-1 rounded-lg hover:text-white">
              {e?.logo && <img src={e.logo} className="w-[1.8rem]" alt="" />} {e.tag}
            </DropdownMenuItem>
          </Link>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default DropDownBox;
