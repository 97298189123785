import React, { useEffect, useState } from "react";
import vicks from "@/assets/vicks.bmp";
import { Button } from "./ui/button";
import { FiPlusSquare } from "react-icons/fi";
import CardComponent from "./CardComponent";
import InputField from "./InputField";
import DialogBox from "./DialogBox";
import AddPatient from "./AddPatient";
import AddAddress from "./AddAddress";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import Heading from "./Heading";
import AlertBox from "./AlertBox";
import { useSelector } from "react-redux";
import PaymentButton from "./PaymentButton";
import ProductCodButton from "./ProductCodButton";
import { MdOutlineContentCopy } from "react-icons/md";

function ProductCartCompo() {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [reload, setReload] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [toBePaid, setToBePaid] = useState(0);
  const userData = useSelector((state) => state.auth?.userData);
  const [allCoupons, setAllCoupons] = useState([]);
  const [discountType, setDiscountType] = useState(null);
  const [charges, setCharges] = useState([]);
  const [appliedCharges, setAppliedCharges] = useState([]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [couponMessage, setCouponMessage] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/cart/products/product`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res);
      const allData = res.data.data;
      let totalAmount = 0;
      allData?.map((e) => {
        totalAmount = totalAmount + e?.price;
      });
      setProductData(res.data.data);
      setSelectedItem(res.data.data);
      setTotalPrice(totalAmount);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCharges() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/charges/client/get`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setCharges(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCoupons() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/coupon/client/get/product`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setAllCoupons(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteProduct(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/cart/product/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
      //console.log(res);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Product deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function applyCoupon() {
    //console.log(selectedCoupon);
    if (selectedCoupon.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please enter a coupon");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/coupon/get/${selectedCoupon}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      if (res.data.data?.couponFor != "product") {
        setAppliedCoupon(false);
        setCouponMessage("This coupon is not for products");
        return null;
      }
      setCouponDiscount(res.data.data.discount);
      setDiscountType(res.data.data.discountType);
      setAppliedCoupon(true);
      setCouponMessage("Coupon applied");
    } catch (error) {
      setAppliedCoupon(false);
      setCouponMessage("Coupon apply failed");
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (discountType === "percentage") {
      setToBePaid(totalPrice - (couponDiscount / 100) * totalPrice);
    } else {
      if (couponDiscount < totalPrice) {
        setToBePaid(totalPrice - couponDiscount);
      } else {
        if (couponDiscount > 0) {
          setOpen(true);
          setSuccess(false);
          setMessage("Total amount must me bigger than coupon discount");
        }
        setCouponDiscount(0);
        setToBePaid(totalPrice);
      }
    }
  }, [totalPrice, couponDiscount]);

  useEffect(() => {
    let validCharges = [];
    let chargeAmount = 0;

    charges.forEach((e) => {
      if (e?.minimumAmount > totalPrice) {
        validCharges.push(e);
        chargeAmount += e?.chargeAmount; // Accumulate chargeAmount
      }
    });

    setAppliedCharges(validCharges);
    setTotalCharge(chargeAmount);

    // Use prev value for toBePaid update to ensure correct value is added
    setToBePaid((prev) => prev + chargeAmount);
  }, [totalPrice, couponDiscount]); // Consider depending on 'charges' instead of 'toBePaid' to avoid a loop

  useEffect(() => {
    fetchData();
    fetchCoupons();
    fetchCharges();
  }, [reload]);

  if (productData?.length === 0) {
    return (
      <div className="w-full text-center">
        <Heading>Your cart is empty</Heading>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[80vw] flex justify-center gap-5">
        <div className="w-full lg:w-1/2 space-y-3">
          <div
            className="w-full h-[70vh] space-y-3"
            style={{ scrollbarWidth: "none" }}
          >
            {productData &&
              productData.map((e, i) => (
                <section
                  className="flex flex-col w-full border items-start p-3 rounded-lg"
                  key={i}
                >
                  <div className="w-full flex justify-between items-center mb-2 border-b-[1px] pb-2">
                    <div>
                      <p className="bg-green-500 px-2 rounded-md text-white text-[13px] font-semibold">
                        {e?.product?.type?.toUpperCase()}
                      </p>
                    </div>
                    <Button
                      variant="outline"
                      className="rounded-none w-[8rem] h-[2rem] text-slate-700"
                      onClick={() => {
                        setSelectedItem((prev) => {
                          return [...prev, e];
                        });
                        setTotalPrice((prev) => prev + e?.price);
                      }}
                    >
                      <FiPlusSquare size={"16px"} className="mr-2" />
                      Add Item
                    </Button>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <div>
                      <img
                        src={`${SERVER_URL}/api/v1/fetch/image/${e?.product?.pictures[0]}`}
                        className="w-[7rem]"
                        alt=""
                      />
                    </div>
                    <div className="text-right">
                      <p className="text-[20px] font-bold">
                        {e?.product?.title}
                      </p>
                      <p className="text-[13px] text-slate-500">
                        {e?.product?.mkt}
                      </p>
                      <p className="text-[13px] font-semibold text-slate-500">
                        {e?.product?.groups[e?.groupIndex]?.title}
                      </p>
                      <p className="text-[20px] font-bold">₹{e?.price}</p>
                      <Button
                        variant="destructive"
                        className="mt-3"
                        onClick={() => deleteProduct(e?._id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </section>
              ))}
          </div>
          <div className="flex lg:hidden">
            <DialogBox
              title={"Make A Bill"}
              buttonClass={"w-full bg-[#37B7C3] text-white text-[16px]"}
            >
              <CardComponent width="w-[100%]">
                <div>
                  <p className="pt-5 text-[20px] font-semibold mb-5">
                    Bill Summary
                  </p>
                  <div className="w-full space-y-1 border-b-[1px] pb-3">
                    {selectedItem &&
                      selectedItem?.map((e, i) => (
                        <section
                          className="w-full flex justify-between items-center text-[15px] text-slate-500"
                          key={i}
                        >
                          <p>{e?.product?.title}</p>
                          <p>₹{e?.price}</p>
                        </section>
                      ))}
                  </div>
                  <div className="w-full space-y-1 mt-3 border-b-[1px] pb-3">
                    <section className="w-full flex justify-between items-center text-[15px]">
                      <p className="font-bold">Total Price</p>
                      <p className="font-bold">₹{totalPrice}</p>
                    </section>
                    {totalPrice > 0 ? (
                      <section className="w-full flex flex-col justify-between items-center text-[15px]">
                        {appliedCharges.map((e, i) => (
                          <div className="w-full flex justify-between">
                            <p>{e?.title}</p>
                            <p>₹{e?.chargeAmount}</p>
                          </div>
                        ))}
                      </section>
                    ) : null}
                    {totalPrice > 0 ? (
                      <section className="w-full flex justify-between items-center text-[15px]">
                        <p>Total Charges</p>
                        <p>₹{totalCharge}</p>
                      </section>
                    ) : null}
                    <section className="w-full flex flex-col items-start justify-between gap-2 mt-3 pb-3 border-b-[1px]">
                      <div className="flex items-end w-full justify-between gap-3">
                        <InputField
                          label="Enter Coupon"
                          onChange={(e) => setSelectedCoupon(e.target.value)}
                        />
                        <Button
                          onClick={() => {
                            applyCoupon();
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                      {appliedCoupon === true ? (
                        <p className="text-green-600 font-medium">
                          {couponMessage}
                        </p>
                      ) : (
                        <p className="text-red-600 font-medium">
                          {couponMessage}
                        </p>
                      )}
                      <p className="mb-3 font-semibold">Coupons</p>
                      {allCoupons?.map((j, i) => (
                        <div className="w-full flex justify-between items-center gap-2">
                          <p className="border px-5 py-1 rounded-md">
                            {j?.coupon}
                          </p>
                          <p>
                            Get{" "}
                            {j?.discountType === "amount"
                              ? `Rs. ${j?.discount}`
                              : `${j?.discount}%`}{" "}
                            discount on this coupon
                          </p>
                          <Button
                            variant="ghost"
                            onClick={() =>
                              navigator.clipboard.writeText(j?.coupon)
                            }
                          >
                            <MdOutlineContentCopy className="text-[20px] text-slate-500" />
                          </Button>
                        </div>
                      ))}
                    </section>
                  </div>
                  <div className="w-full mt-3">
                    <section className="w-full flex justify-between items-center text-[19px] text-slate-700 border-b-[1px] pb-3">
                      <p className="font-bold">To Be Paid</p>
                      <p className="font-bold">₹{toBePaid}</p>
                    </section>
                    <section className="w-full flex flex-col items-start justify-between gap-2 mt-3 pb-3 border-b-[1px]">
                      <p className="mb-3 font-semibold">Coupons</p>
                      {allCoupons?.map((j, i) => (
                        <div className="w-full flex justify-between items-end gap-2">
                          <InputField value={j?.coupon} />
                          <Button
                            onClick={() => {
                              applyCoupon(j?.coupon);
                            }}
                          >
                            {j?.discountType === "amount"
                              ? `Rs. ${j?.discount} OFF`
                              : `${j?.discount}% OFF`}
                          </Button>
                        </div>
                      ))}
                    </section>
                    <p className="mt-5 font-semibold">Select Patient</p>
                    <section className="w-full flex justify-start items-start gap-2 mt-3 pb-3 border-b-[1px]">
                      {userData?.patient?.length === 0 && (
                        <section className="flex justify-between items-center gap-2">
                          <p>No Patient Found</p>
                          <AddPatient />
                        </section>
                      )}
                      {userData?.patient?.length > 0 &&
                        userData?.patient?.map((e, i) => (
                          <section
                            className={`border px-3 py-2 cursor-pointer ${
                              selectedPatient?.firstName === e?.firstName
                                ? "border-blue-500 border-2"
                                : ""
                            }`}
                            onClick={() => setSelectedPatient(e)}
                          >
                            <p>{e?.firstName}</p>
                          </section>
                        ))}
                    </section>
                    <p className="mt-5 font-semibold">Select Address</p>
                    <section className="w-full flex justify-start items-start flex-wrap gap-2 mt-3 pb-3 border-b-[1px]">
                      {userData?.address?.length === 0 && (
                        <section className="flex justify-between items-center gap-2">
                          <p>No Patient Found</p>
                          <AddPatient />
                        </section>
                      )}
                      {userData?.address?.length > 0 &&
                        userData?.address?.map((e, i) => (
                          <section
                            className={`border px-3 py-2 cursor-pointer ${
                              selectedAddress?.address === e?.address
                                ? "border-blue-500 border-2"
                                : ""
                            }`}
                            onClick={() => setSelectedAddress(e)}
                          >
                            <p>{e?.address}</p>
                          </section>
                        ))}
                    </section>
                    <section className="w-full flex flex-row gap-2 mt-3">
                      <PaymentButton
                        products={selectedItem}
                        address={selectedAddress}
                        coupon={coupon}
                        discount={couponDiscount}
                        patient={selectedPatient}
                        totalPrice={toBePaid}
                        discountType={discountType}
                      />
                      <ProductCodButton
                        products={selectedItem}
                        address={selectedAddress}
                        coupon={coupon}
                        discount={couponDiscount}
                        patient={selectedPatient}
                        totalPrice={toBePaid}
                        discountType={discountType}
                      />
                    </section>
                  </div>
                </div>
              </CardComponent>
            </DialogBox>
          </div>
        </div>
        <div className="w-1/2 hidden lg:flex flex-col items-center">
          <CardComponent width="w-[100%]">
            <div>
              <p className="pt-5 text-[20px] font-semibold mb-5">
                Bill Summary
              </p>
              <div className="w-full space-y-1 border-b-[1px] pb-3">
                {selectedItem &&
                  selectedItem?.map((e, i) => (
                    <section
                      className="w-full flex justify-between items-center text-[15px] text-slate-500"
                      key={i}
                    >
                      <p>{e?.product?.title}</p>
                      <p>₹{e?.price}</p>
                    </section>
                  ))}
              </div>
              <div className="w-full space-y-1 mt-3 border-b-[1px] pb-3">
                <section className="w-full flex justify-between items-center text-[15px]">
                  <p className="font-bold">Total Price</p>
                  <p className="font-bold">₹{totalPrice}</p>
                </section>
                {totalPrice > 0 ? (
                  <section className="w-full flex flex-col justify-between items-center text-[15px]">
                    {appliedCharges.map((e, i) => (
                      <div className="w-full flex justify-between">
                        <p>{e?.title}</p>
                        <p>₹{e?.chargeAmount}</p>
                      </div>
                    ))}
                  </section>
                ) : null}
                {totalPrice > 0 ? (
                  <section className="w-full flex justify-between items-center text-[15px]">
                    <p>Total Charges</p>
                    <p>₹{totalCharge}</p>
                  </section>
                ) : null}
                <section className="w-full flex justify-between items-center text-[15px] text-green-600">
                  <p>Coupon Discount</p>
                  {discountType === "amount"
                    ? `Rs. ${couponDiscount}`
                    : `${couponDiscount}%`}
                </section>
              </div>
              <div className="w-full mt-3">
                <section className="w-full flex justify-between items-center text-[19px] text-slate-700 border-b-[1px] pb-3">
                  <p className="font-bold">To Be Paid</p>
                  <p className="font-bold">₹{toBePaid}</p>
                </section>
                <section className="w-full flex flex-col items-start justify-between gap-2 mt-3 pb-3 border-b-[1px]">
                  <div className="flex items-end w-full justify-between gap-3">
                    <InputField
                      label="Enter Coupon"
                      onChange={(e) => setSelectedCoupon(e.target.value)}
                    />
                    <Button
                      onClick={() => {
                        applyCoupon();
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                  {appliedCoupon === true ? (
                    <p className="text-green-600 font-medium">
                      {couponMessage}
                    </p>
                  ) : (
                    <p className="text-red-600 font-medium">{couponMessage}</p>
                  )}
                  <p className="mb-3 font-semibold">Coupons</p>
                  {allCoupons?.map((j, i) => (
                    <div className="w-full flex justify-between items-center gap-2">
                      <p className="border px-5 py-1 rounded-md">{j?.coupon}</p>
                      <p>
                        Get{" "}
                        {j?.discountType === "amount"
                          ? `Rs. ${j?.discount}`
                          : `${j?.discount}%`}{" "}
                        discount on this coupon
                      </p>
                      <Button
                        variant="ghost"
                        onClick={() => navigator.clipboard.writeText(j?.coupon)}
                      >
                        <MdOutlineContentCopy className="text-[20px] text-slate-500" />
                      </Button>
                    </div>
                  ))}
                </section>
                <p className="mt-5 font-semibold">Select Patient</p>
                <section className="w-full flex justify-start items-start gap-2 mt-3 pb-3 border-b-[1px]">
                  {userData?.patient?.length === 0 && (
                    <section className="flex justify-between items-center gap-2">
                      <p>No Patient Found</p>
                      <AddPatient />
                    </section>
                  )}
                  {userData?.patient?.length > 0 &&
                    userData?.patient?.map((e, i) => (
                      <section
                        className={`border px-3 py-2 cursor-pointer ${
                          selectedPatient?.firstName === e?.firstName
                            ? "border-blue-500 border-2"
                            : ""
                        }`}
                        onClick={() => setSelectedPatient(e)}
                      >
                        <p>{e?.firstName}</p>
                      </section>
                    ))}
                </section>
                <p className="mt-5 font-semibold">Select Address</p>
                <section className="w-full flex flex-wrap justify-start items-start gap-2 mt-3 pb-3 border-b-[1px]">
                  {userData?.address?.length === 0 && (
                    <section className="flex justify-between items-center gap-2">
                      <p>No Patient Found</p>
                      <AddPatient />
                    </section>
                  )}
                  {userData?.address?.length > 0 &&
                    userData?.address?.map((e, i) => (
                      <section
                        className={`border px-3 py-2 cursor-pointer ${
                          selectedAddress?.address === e?.address
                            ? "border-blue-500 border-2"
                            : ""
                        }`}
                        onClick={() => setSelectedAddress(e)}
                      >
                        <p>{e?.address}</p>
                      </section>
                    ))}
                </section>
                <section className="w-full flex flex-row gap-2 mt-3">
                  <PaymentButton
                    products={selectedItem}
                    address={selectedAddress}
                    coupon={coupon}
                    discount={couponDiscount}
                    patient={selectedPatient}
                    totalPrice={toBePaid}
                    charges={charges}
                    chargeAmount={totalCharge}
                    discountType={discountType}
                  />
                  <ProductCodButton
                    products={selectedItem}
                    address={selectedAddress}
                    coupon={coupon}
                    discount={couponDiscount}
                    patient={selectedPatient}
                    totalPrice={toBePaid}
                    charges={charges}
                    chargeAmount={totalCharge}
                    discountType={discountType}
                  />
                </section>
              </div>
            </div>
          </CardComponent>
        </div>
      </div>
    </div>
  );
}

export default ProductCartCompo;
