import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";

function AdminLabTests() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [catagory, setCatagory] = useState("")
  const [reload, setReload] = useState(false)
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/all`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
    dispatch(delProductData())
  },[])

  function updateData(data) {
    dispatch(setProductData({productData: data}))
    return navigate("/admin/admin-edit-labtest")
  }

  async function delProduct(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/test/delete/${deleteProductId}`,
        method: "delete",
        withCredentials: true,
      });
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addCatagory() {
    console.log(catagory);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/catagory/add`,
        method: "post",
        data: {
          name: catagory
        },
        withCredentials: true,
      });
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Lab Tests</Heading2>
        <div className="w-full flex justify-end gap-2 flex-wrap">
          <DialogBox title={"Add Catagory"}>
            <InputField label="Catagory" onChange={(e) => setCatagory(e?.target?.value)} />
            <Button onClick={addCatagory}>Save</Button>
          </DialogBox>
          <Button variant="outline" asChild>
            <Link to={"/admin/labtest-form"}>Add New Doctor</Link>
          </Button>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-left">Test Name</TableHead>
              <TableHead className="text-center">Catagory</TableHead>
              <TableHead className="text-center">Discount</TableHead>
              <TableHead className="text-center">Total Tests Included</TableHead>
              <TableHead className="text-center">MRP</TableHead>
              <TableHead className="text-center">Price</TableHead>
              <TableHead className="text-center">Edit</TableHead>
              <TableHead className="text-center">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell className="text-left">
                    {e?.title}
                  </TableCell>
                  <TableCell>{e?.catagory}</TableCell>
                  <TableCell>{e?.discount}</TableCell>
                  <TableCell>
                    <DialogBox title={`${e?.includeTests?.length} Tests`} contentTitle={"Included Tests"}>
                      {e?.includeTests?.map((j, i) => (
                        <div className="border px-3 py-3 rounded-md" key={i}>
                          <p>{j}</p>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>{e?.mrp}</TableCell>
                  <TableCell>{e?.price}</TableCell>
                  <TableCell>
                    <Button onClick={() => updateData(e)}>Edit</Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="destructive"
                      onClick={() => delProduct(e?._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminLabTests;
