import Navbar from "@/components/admin/Navbar";
import React from "react";
import { Outlet } from "react-router-dom";

function AdminLayout() {
  return (
    <div className="flex p-0 m-0">
      <Navbar />
      <div className="2xl:ml-[15rem] w-full mt-[4rem] 2xl:mt-0">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminLayout;
