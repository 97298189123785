import React, { useState } from "react";
import Heading2 from "./Heading2";
import DialogBox from "./DialogBox";
import pescriptionFormat from "@/assets/pescription.svg";
import { Button } from "./ui/button";
import InputFile from "./InputFile";
import { useSelector } from "react-redux";
import InputField from "./InputField";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import AlertBox from "./AlertBox";

function UploadPrescription() {
  const userData = useSelector((state) => state.auth?.userData);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [file, setFile] = useState(null)
  const [selectDate, setSelectDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function addPrescription() {
    const formData = new FormData();
    formData.append("prescriptionImg", file);
    formData.append("patient", selectedPatient?.firstName + " " + selectedPatient.lastName);
    formData.append("dateOfIssue", selectDate)
    console.log(formData);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/prescription/upload`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      console.log(res.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully uploaded");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Upload failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex justify-center sm:justify-between items-center flex-wrap border px-5 py-3 rounded-lg bg-[#37B7C3] text-white text-center gap-3">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <Heading2>Upload Your Prescription</Heading2>
      <DialogBox
        title={"Upload Prescription"}
        buttonClass={"text-slate-700 shadow-lg"}
      >
        <div className="w-full flex flex-col justify-center items-center mt-5 gap-5 overflow-auto">
          <img src={pescriptionFormat} className="w-[80%]" alt="" />
          <div className="w-full">
            <p className="font-semibold">Patient</p>
            <section className="w-full flex justify-start mt-2 items-start gap-2">
              {userData?.patient?.length === 0 && (
                <section className="flex justify-between items-center gap-2">
                  <p>No Patient Found</p>
                  <AddPatient />
                </section>
              )}
              {userData?.patient?.length > 0 &&
                userData?.patient?.map((e, i) => (
                  <section
                    className={`border px-3 py-2 cursor-pointer ${
                      selectedPatient?.firstName === e?.firstName
                        ? "border-blue-500 border-2"
                        : ""
                    }`}
                    onClick={() => setSelectedPatient(e)}
                  >
                    <p>{e?.firstName}</p>
                  </section>
                ))}
            </section>
          </div>
          <div className="w-full flex flex-col justify-center items-center gap-2">
            <InputField label="Upload Prescription (PDF/image)" type="file" onChange={(e) => setFile(e.target.files[0])} />
          </div>
          <div className="w-full flex flex-col justify-center items-center gap-2">
            <InputField label="Date of Issue" type="date" onChange={(e) => setSelectDate(e.target.value.toString())} />
            <Button onClick={addPrescription}>Upload</Button>
          </div>
        </div>
      </DialogBox>
    </div>
  );
}

export default UploadPrescription;
