import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    phoneNumber : null
}

const phoneNumberSlice = createSlice({
    name: "phoneNumberSlice",
    initialState,
    reducers: {
        setNumber: (state, action) => {
            state.phoneNumber = action.payload.phoneNumber
        },
        clearNumber: (state) => {
            state.phoneNumber = null
        }
    }
})

export const {setNumber, clearNumber} = phoneNumberSlice.actions
export default phoneNumberSlice.reducer 