import React from "react";
import appnest from "@/assets/appnest_logo.png";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsYoutube,
  BsThreads,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { CiGlobe } from "react-icons/ci";
import { FaGooglePlay } from "react-icons/fa";
import { FaAppStoreIos } from "react-icons/fa";
import DialogBox from "./DialogBox";
import Heading from "./Heading";
import { tAndC, pAndP, cAndR, decl } from "@/t&c";
import { IoCallOutline } from "react-icons/io5";

function Footer() {
  return (
    <footer className="flex flex-col justify-around px-3 items-center py-5 mt-20 border-t-[1px]">
      <div className="flex w-full justify-around gap-[3rem] lg:gap-[10rem] flex-wrap py-10 items-center">
        <div className="flex flex-col justify-center items-center gap-10">
          <img src={appnest} className="w-[20rem]" alt="" />
          <section className="flex gap-5 text-[25px] mt-5">
            <Link
              to={
                "https://www.facebook.com/profile.php?id=61556775867975&mibextid=kFxxJD"
              }
            >
              <BsFacebook className="text-blue-500" />
            </Link>
            <Link
              to={"https://www.instagram.com/appnestit?igsh=ZHpla3AwYmdhNjFm"}
            >
              <BsInstagram className="text-pink-500" />
            </Link>
            <Link to={"https://in.linkedin.com/company/appnestit"}>
              <BsLinkedin className="text-sky-500" />
            </Link>
            <Link to={"https://youtube.com/@AppNestit?si=ybmJ3KeM0_9_4HRZ"}>
              <BsYoutube className="text-red-500" />
            </Link>
            <Link to={"https://www.threads.net/@appnestit"}>
              <BsThreads className="text-black" />
            </Link>
          </section>
        </div>
        <div className="flex flex-col justify-start items-start gap-1 text-slate-600">
          <Link className="flex items-center justify-center gap-2">
            <AiOutlineMail /> vidyalay@appnestit.com
          </Link>
          <Link className="flex items-center justify-center gap-2">
            <CiGlobe /> www.appnestit.com
          </Link>
          <Link className="flex items-center justify-center gap-2 mb-5">
            <IoCallOutline /> +91 9148054905
          </Link>
          <div className="flex flex-col justify-center items-center gap-5">
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.colorcode.vidyalaya.admin"
              }
            >
              <section className="flex w-[13rem] justify-center items-center bg-slate-800 p-3 text-white rounded-full gap-3">
                <FaGooglePlay size={"25px"} />
                <div>
                  <p className="text-[8px]">GET ON</p>
                  <p className="text-[15px] font-medium">Google Play Store</p>
                </div>
              </section>
            </Link>
            <Link>
              <section className="flex w-[13rem] justify-center items-center bg-slate-800 p-3 text-white rounded-full gap-3">
                <FaAppStoreIos size={"25px"} />
                <div>
                  <p className="text-[8px]">GET ON</p>
                  <p className="text-[15px] font-medium">iOS App Store</p>
                </div>
              </section>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-slate-300 mb-5"></div>
      <p className="text-slate-700 text-[13px] text-center">
        Office Address : S.No, ALPINE VIVA, 139, Whitefield - Hoskote Rd, Sai
        Gardens, Seegehalli, Krishnarajapura, Bengaluru, Karnataka, 560067
      </p>
      <p className="mt-5 text-[13px] text-slate-600 text-center">
        COPYRIGHT © 2024 - AppNest Information Technology Private Limited, India
        •{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Privacy & Policy"}
            buttonClass={"border-none bg-transparent text-[13px] h-[2px]"}
          >
            <Heading>Privacy & Policy</Heading>
            <p>{pAndP}</p>
          </DialogBox>
        }{" "}
        •{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Terms & Conditions"}
            buttonClass={"border-none bg-transparent text-[13px] h-[2px]"}
          >
            <Heading>Terms & Conditions</Heading>
            <p>{tAndC}</p>
          </DialogBox>
        }{" "}
        •{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Cancellation & Refund"}
            buttonClass={"border-none bg-transparent text-[13px] h-[2px]"}
          >
            <Heading>Cancellation & Refund</Heading>
            <p>{cAndR}</p>
          </DialogBox>
        }
        •{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Decleration"}
            buttonClass={"border-none bg-transparent text-[13px] h-[2px]"}
          >
            <Heading>Decleration</Heading>
            <p>{decl}</p>
          </DialogBox>
        }{" "}
      </p>
    </footer>
  );
}

export default Footer;
