import React, { useEffect, useState } from "react";
import vicks from "@/assets/vicks.bmp";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

function MyAppointments() {
  const [labData, setLabData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/my-appointments`,
        method: "get",
        withCredentials: true,
      });
      setLabData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log(labData);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-3">
      {labData &&
        labData?.map((e, i) => (
          <section
            className="flex flex-col w-[16rem] lg:w-[50rem] border items-start p-3 rounded-lg flex-shrink-0"
            key={i}
          >
            <div className="w-full flex justify-between items-center mb-2 border-b-[1px] pb-2">
              <div>
                <p className="bg-green-500 px-2 rounded-md text-white text-[13px] font-semibold">
                  DOCTOR
                </p>
              </div>
              <div>
                <p className="bg-green-500 px-2 rounded-md text-white text-[13px] font-semibold">
                  {e?.appointmentStatus}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-between items-center flex-col lg:flex-row">
              <div className="w-[7rem] h-[7rem]">
                <img
                  src={`${SERVER_URL}/api/v1/fetch/image/${e?.doctor?.picture}`}
                  className="w-full h-full rounded-full object-cover"
                  alt=""
                />
              </div>
              <div className="text-left lg:text-right">
                <p className="text-[20px] font-bold">{e?.doctor?.name}</p>
                <p className="text-[13px] text-slate-500">
                  {e?.doctor?.designation}
                </p>
                <p className="text-[13px] font-semibold text-slate-500">
                  {e?.appointmentDate?.date} at {e?.appointmentDate?.time}
                </p>
                <p className="text-[20px] font-bold">₹{e?.fees}</p>
                {e?.report?.length > 0 ? (
                  <Button asChild>
                    <Link
                      to={`${SERVER_URL}/api/v1/fetch/image/${e?.report}`}
                      target="_blank"
                    >
                      Download Prescription
                    </Link>
                  </Button>
                ) : (
                  <Button disabled>Download Prescription</Button>
                )}
              </div>
            </div>
          </section>
        ))}
    </div>
  );
}

export default MyAppointments;
