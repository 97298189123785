import CardComponent from "@/components/CardComponent";
import React, { useEffect, useState } from "react";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { useSelector } from "react-redux";
import { SERVER_URL } from "@/constant";
import { useForm } from "react-hook-form";
import AddAddress from "@/components/AddAddress";
import AddPatient from "@/components/AddPatient";
import Loading from "@/components/Loading";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import Heading2 from "@/components/Heading2";

function AdminCompanyInfo() {
  const { register, handleSubmit } = useForm();
  const userData = useSelector((state) => state.auth?.userData);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [reload, setReload] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  async function updateData(data) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/company/info/update`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/company/info`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data);
      setCompanyData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  console.log(companyData);

  return (
    <div className="w-full flex flex-col justify-center items-center h-[70vh] py-3">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <Heading2>Company Profile</Heading2>
      <div className="w-[90vw] flex flex-col justify-center items-center py-5 h-full">
        <CardComponent width="w-[90%]">
          <div className="flex flex-col p-3">
            <div className="w-full flex flex-col gap-5">
              <form
                className="mt-5 w-full flex flex-col justify-center items-center gap-5"
                onSubmit={handleSubmit(updateData)}
                onChange={()=>setButtonActive(true)}
              >
                <div className="flex w-full flex-col items-center justify-center gap-10">
                  <div className="w-full justify-center items-center flex flex-col gap-5">
                    <div className="bg-[#37B7C3] w-full text-center py-3 text-white font-bold">
                      Company Information
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="Company Name"
                          defaultValue={companyData?.name}
                          {...register("name")}
                        />
                      </div>
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="Address"
                          defaultValue={companyData?.address}
                          {...register("address")}
                        />
                      </div>
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="Phone Number"
                          defaultValue={companyData?.phone}
                          {...register("phone")}
                        />
                      </div>
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="Email"
                          {...register("email")}
                          defaultValue={companyData?.email}
                        />
                      </div>
                      <div className="w-[100%] sm:w-[15rem]">
                        <InputField
                          label="GSTIN Number"
                          {...register("gstNo")}
                          defaultValue={companyData?.gstNo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {buttonActive === true ? (
                  <Button className="">Save</Button>
                ) : (
                  <Button disabled className="">Save</Button>
                )}
              </form>
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  );
}

export default AdminCompanyInfo;
