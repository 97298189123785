import React, { useEffect, useState } from "react";
import logo from "@/assets/logo.png";
import { IoHomeOutline } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Button } from "../ui/button";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { logout } from "@/store/auth.slice";
import { SERVER_URL } from "@/constant";
import axios from "axios";

function Navbar() {
  const [open, setOpen] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logoObj, setLogoObj] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/website/client/logonav`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      const allData = res.data.data;
      let logoData = {};
      allData?.map((e, i) => {
        if (e?.type === "logo") {
          logoData.link = e?.link;
          logoData.title = e?.title;
          logoData.image = e?.image;
        }
      });
      setLogoObj(logoData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    fetchData()
  },[])

  async function logoutUser() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/auth/logout`,
        method: "get",
        withCredentials: true,
      });
      if (res.status >= 200 || res.status < 400) {
        dispatch(logout());
        navigate("/");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const items = [
    {
      heading: "Company Profile",
      link: "/admin/admin-company-info"
    },
    {
      heading: "Logo and Navigations",
      link: "/admin/admin-logonav"
    },
    {
      heading: "Products",
      subNavArray: [
        {
          headings: "All Products",
          link: "/admin/products",
        },
        {
          headings: "Add New Product",
          link: "/admin/product-form",
        },
        {
          headings: "Due Orders",
          link: "/admin/due-orders",
        },
        {
          headings: "All Orders",
          link: "/admin/all-orders",
        },
        {
          headings: "Brands",
          link: "/admin/admin-brand",
        },
        {
          headings: "Catagory",
          link: "/admin/admin-product-catagory",
        },
        {
          headings: "Subcatagory",
          link: "/admin/admin-product-subcatagories",
        },
      ],
    },
    {
      heading: "Doctors",
      subNavArray: [
        {
          headings: "All Doctors",
          link: "/admin/admin-doctors",
        },
        {
          headings: "Add New Doctor",
          link: "/admin/doctors-form",
        },
        {
          headings: "Scheduled Appointments",
          link: "/admin/scheduled-appointments",
        },
        {
          headings: "All Appointments",
          link: "/admin/all-appointments",
        },
      ],
    },
    {
      heading: "Lab Tests",
      subNavArray: [
        {
          headings: "All Lab Tests",
          link: "/admin/admin-tests",
        },
        {
          headings: "Add New Test",
          link: "/admin/labtest-form",
        },
        {
          headings: "Scheduled Tests",
          link: "/admin/scheduled-tests",
        },
        {
          headings: "All Orderd Tests",
          link: "/admin/all-ordered-tests",
        },
      ],
    },
    {
      heading: "Prescriptions",
      link: "/admin/admin-prescriptions"
    },
    {
      heading: "Pincodes",
      link: "/admin/admin-pincode"
    },
    {
      heading: "Banners",
      link: "/admin/admin-banner"
    },
    {
      heading: "Coupons",
      link: "/admin/admin-coupons"
    },
    {
      heading: "Charges",
      link: "/admin/admin-charges"
    },
  ];

  return (
    <div className="w-[100%] border-slate-200 border h-[4rem] bg-white top-0 left-0 fixed 2xl:w-[15rem] z-[1] 2xl:h-[100vh]">
      <div className="w-full flex justify-between items-center py-2 px-2 2xl:hidden ">
        <Button variant="ghost" onClick={() => setNavOpen(true)}>
          <RxHamburgerMenu className="text-[30px]" />
        </Button>
        <Link to={"/admin"}><img src={logo} className="w-[8rem]" alt="" /></Link>
      </div>
      <div className="w-full hidden justify-center items-center py-5 2xl:flex flex-col">
        <section>
          <img src={`${SERVER_URL}/api/v1/fetch/image/${logoObj?.image}`} className="w-[10rem]" alt="" />
        </section>
        <div className="w-full flex flex-col mt-10 gap-5">
          <span className="flex items-center gap-3 border-l-[5px] border-sky-600 px-5 h-[3rem] bg-sky-100">
            <IoHomeOutline size={"15px"} />
            <Link to={"/admin"} className="hover:text-sky-500">
              Dashboard
            </Link>
          </span>
          <div className="px-3 w-full flex flex-col gap-5">
            {items.map((e, index) =>
              dropdown(
                e.heading,
                index,
                e.subNavArray,
                e.link,
                e.icon,
                open,
                setOpen,
                index
              )
            )}
            <Button
              className="text-[16px] bg-red-500 hover:bg-red-700 font-semibold"
              onClick={logoutUser}
            >
              <IoIosLogOut className="text-white mr-3" size={"25px"} /> Logout
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`w-full h-[100vh] bg-[#63636348] fixed top-0 left-0 transition-all duration-300 ease-in-out ${
          navOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={(e) => {
          setNavOpen(false);
        }}
      >
        <div
          className={`w-[15rem] bg-white h-full fixed left-0 top-0 py-5 flex flex-col items-center 
    transition-transform duration-300 ease-in-out transform ${
      navOpen ? "translate-x-0" : "-translate-x-full"
    }`}
        >
          <section>
            <img src={logo} className="w-[10rem]" alt="" />
          </section>
          <div className="w-full flex flex-col mt-10 gap-5">
            <span className="flex items-center gap-3 border-l-[5px] border-sky-600 px-5 h-[3rem] bg-sky-100">
              <IoHomeOutline size={"15px"} />
              <Link to={"/admin"} className="hover:text-sky-500">
                Dashboard
              </Link>
            </span>
            <div className="px-3 w-full flex flex-col gap-5">
              {items.map((e, index) =>
                dropdown(
                  e.heading,
                  index,
                  e.subNavArray,
                  e.link,
                  e.icon,
                  open,
                  setOpen,
                  index,
                  setNavOpen
                )
              )}
              <Button
                className="text-[16px] bg-red-500 hover:bg-red-700 font-semibold"
                onClick={logoutUser}
              >
                <IoIosLogOut className="text-white mr-3" size={"25px"} /> Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function dropdown(
  heading,
  index,
  subNavArray,
  link,
  icon,
  open,
  setOpen,
  key,
  setNavOpen
) {
  const openDropdown = open === index;

  return (
    <section className="w-full" key={key}>
      <div
        className={`flex justify-between cursor-pointer items-center w-full px-4 rounded-2xl font-medium ${
          openDropdown === true ? "text-slate-900" : "bg-white text-slate-400"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(openDropdown ? null : index);
        }}
      >
        <span className="flex items-center gap-3">
          {subNavArray && (
            <h1 className="hover:text-sky-500 font-semibold">{heading}</h1>
          )}
          {!subNavArray && (
            <h1 className="hover:text-sky-500 font-semibold">
              <Link to={link} className="w-full font-semibold">
                {heading}
              </Link>
            </h1>
          )}
        </span>
        {subNavArray && openDropdown === true ? (
          <FaMinus size={"12px"} className="text-slate-500 hidden lg:block" />
        ) : subNavArray?.length > 0 ? (
          <FaPlus size={"12px"} className="text-slate-500 hidden lg:block" />
        ) : null}
      </div>
      <ul className="w-full mt-2 border-l-[1px] pl-[1.5rem] space-y-3 border-sky-500">
        {openDropdown &&
          subNavArray &&
          subNavArray.map((e, index) => (
            <li key={index}>
              <Link
                to={e.link}
                className="text-slate-500 hover:text-slate-600 w-full"
              >
                {e.headings}
              </Link>
            </li>
          ))}
      </ul>
    </section>
  );
}

export default Navbar;
