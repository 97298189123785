import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Link, useNavigate } from "react-router-dom";
import AddToCart from "./AddToCart";
import { SERVER_URL } from "@/constant";
import { IoMdStar } from "react-icons/io";

function ProductCard({ data }) {
  console.log(data);

  return (
    <Card className="w-[260px] py-2 border-[1px]">
      <CardContent>
        <section className="w-full flex justify-start gap-2">
          {data?.discount > 0 && (
            <p className="bg-green-600 font-semibold px-2 rounded-lg text-white text-[13px]">
              {data?.discount}% off
            </p>
          )}
          <p className="bg-green-100 font-medium px-2 rounded-lg text-green-800 text-[13px]">
            {data?.catagory}
          </p>
        </section>
        <div className="flex flex-col justify-center items-center gap-2">
          <Link
            className="w-full cursor-pointer flex flex-col justify-center items-center gap-3"
            to={`/product/${data?._id}`}
          >
            <div className="w-[8rem] h-[8rem] mt-3">
              <img
                src={`${SERVER_URL}/api/v1/fetch/image/${data?.pictures[0]}`}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="text-left w-full">
              <div className="w-full flex items-center"></div>
              <p className="text-left font-medium">{data?.title}</p>
              <p className="flex justify-center items-center bg-green-600 w-[3rem] mt-2 mb-2 text-white font-semibold rounded-[4px] px-2 py-[2px] text-[12px]">
                {Math.round(Number(data?.avgRating) * 10) / 10}
                <span>
                  <IoMdStar size={"15px"} />
                </span>
              </p>
              <p className="text-[14px] text-slate-500">MKT : {data?.mkt}</p>
              <p className="font-semibold">
                Best Price : Rs. {data?.groups[0]?.price}
              </p>
              <strike className="text-[12px] text-red-500 font-medium">
                MRP : {data?.groups[0]?.mrp}
              </strike>
            </div>
          </Link>
          <section className="flex justify-center gap-2 w-full">
            <AddToCart
              groupIndex={0}
              price={data?.groups[0]?.price}
              productId={data?._id}
            />
          </section>
        </div>
      </CardContent>
    </Card>
  );
}

export default ProductCard;
