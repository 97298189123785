import React, { useState } from "react";
import { Button } from "./ui/button";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import logo from "../assets/logo.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertBox from "./AlertBox";

function LabCodButton({
  testId,
  totalPrice,
  coupon,
  discount,
  address,
  patient,
  testDate,
  place,
}) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const userData = useSelector((state) => state.auth?.userData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function makePayment() {
    if (!userData) {
      navigate("/signin")
      return null
    }
    if (totalPrice === 0 || !address || !patient || !testDate) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select patient and address");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/test/cash-on-delivary`,
        method: "post",
        data: {
          testId,
          totalPrice,
          coupon,
          discount,
          address,
          patient,
          testDate,
          place,
        },
        withCredentials: true,
      });
      console.log(res);
      navigate("/my-lab-order?success=true");
    } catch (error) {
      navigate("/my-lab-order?success=false");
    } finally {
      setLoading(false);
    }
  }

  //console.log(window);

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <Button className="w-full" onClick={makePayment}>
        Pay At Lab
      </Button>
    </>
  );
}

export default LabCodButton;
