import Heading from "@/components/Heading";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import doctor from "@/assets/doctorImg.svg";
import prescription from "@/assets/prescriptionImg.svg";
import lab from "@/assets/lab.svg";
import order from "@/assets/order.svg";
import appointment from "@/assets/appointment.svg";
import { IoIosArrowForward } from "react-icons/io";
import { FaCircleUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

function AdminLanding() {
  const userData = useSelector((state) => state.auth?.userData);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dueTests, setDueTests] = useState([]);
  const [dueAppointments, setDueAppointments] = useState([]);
  const [dueOrders, setDueOrders] = useState([]);

  async function fetchOrderData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/products/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueOrders = allData.filter(
        (e) => e?.delivaryStatus === "initiated"
      );
      setDueOrders(dueOrders);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchLabData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/tests/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueTests = allData.filter((e) => e?.testStatus === "initiated");
      setDueTests(dueTests);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchAppointmentData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/appointments/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueAppointments = allData.filter(
        (e) => e?.appointmentStatus === "scheduled"
      );
      setDueAppointments(dueAppointments);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchOrderData();
    fetchAppointmentData();
    fetchLabData();
  }, []);

  //console.log(dueOrders);
  console.log(dueTests);
  //console.log(dueAppointments);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5 gap-3 flex flex-col justify-center items-center">
      <div className="w-full text-center">
        <Heading>Admin Dashboard</Heading>
      </div>
      <div className="w-full flex flex-wrap justify-center items-center gap-5 mt-10 flex-col sm:flex-row">
        <section className="border flex gap-5 h-[auto] px-5 py-3 rounded-md w-[90vw] sm:w-[25rem]">
          <img src={order} className="w-[5rem]" alt="" />
          <div className="w-full h-full flex flex-col justify-between">
            <p className="font-normal text-[14px] sm:text-[16px]">
              Due Product Orders
            </p>
            <p className="text-[30px] sm:text-[50px] font-bold">
              {dueOrders?.length}
            </p>
            <div className="w-full flex gap-3 flex-wrap">
              <Link
                to={"/admin/due-orders"}
                className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
              >
                View All <IoIosArrowForward />
              </Link>
              <Link
                to={"/admin/all-orders"}
                className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
              >
                History <IoIosArrowForward />
              </Link>
            </div>
          </div>
        </section>
        <section className="border flex gap-5 h-[auto] px-5 py-3 rounded-md w-[90vw] sm:w-[25rem]">
          <img src={appointment} className="w-[5rem]" alt="" />
          <div className="w-full h-full flex flex-col justify-between">
            <p className="font-normal text-[14px] sm:text-[16px]">
              Due Appointments
            </p>
            <p className="text-[30px] sm:text-[50px] font-bold">
              {dueAppointments?.length}
            </p>
            <div className="w-full flex gap-3 flex-wrap">
              <Link
                to={"/admin/scheduled-appointments"}
                className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
              >
                View All <IoIosArrowForward />
              </Link>
              <Link
                to={"/admin/all-appointments"}
                className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
              >
                History <IoIosArrowForward />
              </Link>
            </div>
          </div>
        </section>
        <section className="border flex gap-5 h-[auto] px-5 py-3 rounded-md w-[90vw] sm:w-[25rem]">
          <img src={lab} className="w-[5rem]" alt="" />
          <div className="w-full h-full flex flex-col justify-between">
            <p className="font-normal text-[14px] sm:text-[16px]">
              Due Lab Tests
            </p>
            <p className="text-[30px] sm:text-[50px] font-bold">
              {dueTests?.length}
            </p>
            <div className="w-full flex gap-3 flex-wrap">
              <Link
                to={"/admin/scheduled-tests"}
                className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
              >
                View All <IoIosArrowForward />
              </Link>
              <Link
                to={"/admin/all-ordered-tests"}
                className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
              >
                History <IoIosArrowForward />
              </Link>
            </div>
          </div>
        </section>
        <section className="border flex gap-5 h-[auto] px-5 py-3 rounded-md w-[90vw] sm:w-[30rem]">
          <img src={doctor} className="w-[5rem]" alt="" />
          <div className="w-full h-full flex flex-col justify-between">
            <p className="text-[20px] sm:text-[30px] font-bold">
              Get All Doctors
            </p>
            <Link to={"/admin/admin-doctors"} className="text-[16px] sm:text-[20px] font-bold flex items-center gap-3 text-sky-500">
              View All <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex gap-5 h-[auto] px-5 py-3 rounded-md w-[90vw] sm:w-[30rem]">
          <img src={prescription} className="w-[5rem]" alt="" />
          <div className="w-full h-full flex flex-col justify-between">
            <p className="font-bold  text-[20px] sm:text-[30px]">
              All Prescriptions
            </p>
            <Link to={"/admin/admin-prescriptions"} className="text-[16px] sm:text-[20px] font-bold flex items-center gap-3 text-sky-500">
              View All <IoIosArrowForward />
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdminLanding;
