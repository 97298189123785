import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import AlertBox from "@/components/AlertBox";
import { Link } from "react-router-dom";

function AdminPrescriptions() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/prescriptions/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      allData.patient = allData?.patient?.slice(2, -2);
      console.log(allData);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  //console.log(productsData);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3 overflow-auto">
        <Heading2>All Prescriptions</Heading2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Patient Name</TableHead>
              <TableHead className="text-center">Mobile Number</TableHead>
              <TableHead className="text-center">Date of Issue</TableHead>
              <TableHead className="text-center">
                Download Prescription
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.patient}</TableCell>
                  <TableCell>{e?.user?.mobileNumber}</TableCell>
                  <TableCell>{e?.dateOfIssue}</TableCell>
                  <TableCell>
                    <Button asChild>
                      <Link
                        to={`${SERVER_URL}/api/v1/fetch/image/${e?.prescriptionImg}`} target="_blank"
                      >Download Prescription</Link>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminPrescriptions;
