import React, { useRef } from "react";
import ProductCard from "./ProductCard";
import { handleMouseDown } from "@/utils/mouseScroll";

function GroupCarousel({ dataArray }) {
  const scrollRef = useRef(null);
  //console.log(dataArray);

  return (
    <div
      className="flex justify-start items-center gap-5 overflow-auto cursor-grabbing"
      style={{ scrollbarWidth: "none" }}
      ref={scrollRef}
      onMouseDown={(e) => handleMouseDown(e, scrollRef)}
    >
      {dataArray &&
        dataArray.map((e, i) => (
          <div key={i}>
            <ProductCard
              data={e}
            />
          </div>
        ))}
    </div>
  );
}

export default GroupCarousel;
