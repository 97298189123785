import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

function AlertBox({ title, desc, setOpen, open, success }) {
  if (open) {
    setTimeout(() => setOpen(false), 3000);
  }

  if (open && success === true) {
    return (
      <div className="w-[16rem] sm:w-[20rem] fixed right-5 top-10 items-center z-[1000] shadow-2xl">
        <div className="bg-green-700 text-white py-4 px-4 rounded-md">
          <span className="w-full flex items-center justify-between gap-2">
            <h1 className="text-white font-medium">{title}</h1>
            <RxCross2
              className="text-[30px] cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </span>
          {desc && <p className="w-[80%] text-[15px] text-white">{desc}</p>}
        </div>
      </div>
    );
  }

  if (open && success === false) {
    return (
      <div className="w-[16rem] fixed right-5 top-5 items-center z-[1000] shadow-2xl">
        <div className="bg-red-700 text-white py-4 px-4 rounded-md">
          <span className="w-full flex items-center justify-between">
            <h1 className="text-white font-medium">{title}</h1>
            <RxCross2
              className="text-[20px] cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </span>
          {desc && <p className="w-[80%] text-[15px] text-white">{desc}</p>}
        </div>
      </div>
    );
  }
}

export default AlertBox;
