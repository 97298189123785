import CarouselCompo from "@/components/CarouselCompo";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import vitamins from "@/assets/vitamins.png";
import ayurvedic from "@/assets/ayurvedic.png";
import creams from "@/assets/creams_oils_lotions.png";
import face_wash from "@/assets/face_wash_cleansers.png";
import feminine_hygen from "@/assets/feminine_hygiene_2.png";
import fitness from "@/assets/fitness.png";
import mens_grooming from "@/assets/mens_grooming.png";
import orthopedics from "@/assets/orthopaedics.png";
import shampoos from "@/assets/shampoos.png";
import nivea from "@/assets/nivea.png";
import nestle from "@/assets/Nestle-Logo.png";
import drorganic from "@/assets/drorganic.jpg";
import dabur from "@/assets/dabur.png";
import evion from "@/assets/evion.png";
import revital from "@/assets/revital.png";
import durex from "@/assets/durex.png";
import banner1 from "@/assets/banner1.jpeg";
import banner2 from "@/assets/banner2.jpeg";
import vicks from "@/assets/vicks.bmp";
import skin_care from "@/assets/skin_care.png";
import GroupCarousel from "@/components/GroupCarousel";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import axios from "axios";
import { SERVER_URL } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "@/store/auth.slice";
import PincodeCompo from "@/components/PincodeCompo";

function Landing() {

  const [loading, setLoading] = useState(false);
  const [carouselImgsArray, setCarouselImgsArray] = useState([]);
  const [trendingToday, setTrendingToday] = useState([]);
  const [limitedDeals, setLimitedDeals] = useState([]);
  const [bestsellers, setBestsellers] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [brands, setBrands] = useState([])

  async function fetchBanners() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/banner/all`);
      //console.log(res.data.data);
      setCarouselImgsArray(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchTrendingToday() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/keyword/trending_today`
      );
      //console.log(res.data.data);
      setTrendingToday(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchLimitedDeals() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/keyword/limited_deal`
      );
      //console.log(res.data.data);
      setLimitedDeals(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchBestseller() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/keyword/bestseller`
      );
      // console.log(res.data.data);
      setBestsellers(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCatagories() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/products/catagories`);
      //console.log(res.data.data);
      setCatagories(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchBrands() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/products/brands`);
      //console.log(res.data.data);
      setBrands(res.data?.data)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBanners();
    fetchTrendingToday();
    fetchLimitedDeals();
    fetchBestseller();
    fetchCatagories();
    fetchBrands()
  }, []);

  if (loading) {
    return <Loading color={"#37B7C3"} />;
  }

  
  return (
    <div className="w-full flex flex-col justify-center items-center gap-[2rem] lg:gap-[2rem] text-slate-700 m">
      <div className="w-[90vw] h-[46%] mt-[5px] lg:mt-[1rem]">
        <CarouselCompo imgArray={carouselImgsArray} />
      </div>
      <div className="w-[90vw] text-center text-slate-800 flex flex-col justify-center py-5 items-center">
        <div className="w-full flex justify-between items-center">
          <Heading2>Trending Today</Heading2>
          <Link className="font-semibold text-[#37B7C3] text-[17px] hidden sm:flex" to={"/products"}>See All</Link>
        </div>
        <div className="w-full flex justify-between">
          <GroupCarousel dataArray={trendingToday} />
        </div>
        <Link className="font-semibold mt-5 text-[#37B7C3] text-[17px] flex sm:hidden" to={"/products"}>See All</Link>
      </div>
      <div className="w-[95vw] lg:w-[90vw] flex flex-col justify-center items-center text-center">
        <div className="w-full text-left mb-2">
          <Heading2>Shop By Catagory</Heading2>
        </div>
        <div className="w-full flex flex-wrap gap-2 justify-center items-center">
          {catagories &&
            catagories.map((e, i) => (
              <Link key={i} to={`/products/catagory/${e.name}`}>
                <section className="flex flex-col w-[30vw] lg:w-[17vw] md:w-[13rem] items-center gap-1 cursor-pointer text-center">
                  <div className="w-[70%] h-[70%]">
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e.image}`}
                      className="w-full h-full object-cover"
                      alt=""
                    />
                  </div>
                  <p className="font-medium text-[12px] lg:text-[14px] mt-2">
                    {e.name}
                  </p>
                </section>
              </Link>
            ))}
        </div>
      </div>
      <div className="w-full bg-[#37B7C3] text-center text-white flex flex-col justify-center py-5 items-center">
        <div className="w-[90vw] text-left">
          <p className="font-medium">Exclusive Offers On</p>
          <Heading2>Limited Time Deals</Heading2>
          <p className="font-medium mt-[-8px] text-slate-800">
            Grab before the deal expires
          </p>
        </div>
        <div className="w-[85vw] mt-5 mb-5">
          <GroupCarousel dataArray={limitedDeals} />
        </div>
      </div>
      <div className="w-[90vw] text-slate-800 flex flex-col justify-center py-5 items-center">
        <div className="w-full text-left">
          <Heading2>Bestsellers</Heading2>
        </div>
        <div className="w-[85vw] mt-5 mb-5 flex">
          <GroupCarousel dataArray={bestsellers} />
        </div>
      </div>
      <div className="w-[90vw] flex flex-wrap">
        <Heading2>Shop By Brand</Heading2>
        <div className="w-full flex flex-wrap justify-center xl:justify-between items-center mt-10 gap-10">
          {brands &&
            brands.map((e, i) => (
              <Link key={i} to={`/products/brands/${e.name}`}>
                <img src={`${SERVER_URL}/api/v1/fetch/image/${e.image}`} className="w-[4rem] sm:w-[6rem]" alt="" />
              </Link>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Landing;
